<template>
  <div>
    <h1>Add New Part</h1>
    <h1 v-if="loading"><Loading /></h1>
    <div v-else>
      <router-link class="router-link" to="/app">
        <Button text="Return Home" />
      </router-link>
      <br /><br />
      <part-form
        :showDeleteButton="false"
        primaryButtonText="Create"
        :initialPartData="partData"
        v-model="partData"
        v-on:save="createPart()"
      />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading.vue";
import Button from "@/components/common/Button.vue";
import PartForm from "@/components/PartForm.vue";

export default {
  data() {
    return {
      partData: {name: "", LCSCpartnumber: "", location: "", description: "", value: "", datasheet: ""},
      loading: false,
    };
  },
  components: { Loading, Button, PartForm },
  methods: {
    async createPart() {
      this.loading = true;
      var response = await fetch(
        `${process.env.VUE_APP_BACKEND_SERVER_URL}/create-part`,
        {
          method: "PUT",
          headers: {
            "Content-Type":"application/json",
            Authorization: localStorage.getItem("Authorization"),

          },
          body: JSON.stringify({
            "partData": this.partData
          })
        }
      );
      console.log(response);
      if (response.status == 200) {
        this.partData.name = "";
        this.partData.LCSCpartnumber = "";
        this.partData.location = "";
        this.partData.description = "";
        this.partData.value = "";
        this.partData.datasheet = "";
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/styles/style.sass'
</style>
