<template>
  <ais-instant-search
    class="searchBar"
    :search-client="searchClient"
    :index-name="indexName"
  >
    <ais-search-box />
    <ais-hits>
      <template v-slot:item="{ item }">
        <div class="partInformation">
          <div>
            <div class="name">{{ item.name }}</div>
            <br />
            <div v-if="item.LCSCpartnumber != ''" class="detail">
              LCSC: {{ item.LCSCpartnumber.toUpperCase() }}
            </div>
            <div v-if="item.location != ''" class="detail">
              Location: {{ item.location }}
            </div>
            <div v-if="item.value != ''" class="detail">
              Value: {{ item.value }}
            </div>
            <div v-if="item.description != ''" class="detail">
              {{ item.description }}
            </div>
            <a
              v-if="item.datasheet != ''"
              :href="item.datasheet"
              target="_blank"
              >Datasheet</a
            >
          </div>
          <div>
            <router-link class="router-link"
              :to="'/edit-part/' + item.objectID"
              ><font-awesome-icon
                class="icon-button"
                :icon="icons.faEllipsisV"
                size="1x"
            /></router-link>
          </div>
        </div>
      </template>
    </ais-hits>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import "instantsearch.css/themes/satellite-min.css";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        faEllipsisV,
      },
      indexName: process.env.VUE_APP_ALGOLIA_INDEX_NAME,
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APPLICATION_ID,
        localStorage.getItem('algolia_api_key')
      ),
    };
  },
};
</script>

<style lang="sass" scoped>
@import 'src/styles/style.sass'

.searchBar
    max-width: 1000px
    margin: auto

.partInformation
    width: 100%
    text-align: start
    border-radius: 5px
    display: flex
    justify-content: space-between

    .name
        font-size: 30px

    .detail
        font-size: 20px
        padding-top: 4px
</style>
