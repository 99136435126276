import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import CreatePart from "@/views/CreatePart.vue";
import EditPart from "@/views/EditPart.vue";
import Login from "@/views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/app",
    name: "Home",
    component: Home,
  },
  {
    path: "/create-part",
    name: "CreatePart",
    component: CreatePart,  
  },
  {
    path: "/edit-part/:objectID",
    name: "EditPart",
    component: EditPart,  
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;