<template>
    <div class="button" :style="{ borderColor: color}">
        {{ text }}
    </div>
</template>

<script>
export default{
    props: {
        text: { type: String, required: true },
        color: { type: String, required: false, default: "#2E86C1"}
    },
}
</script>

<style lang="sass" scoped>
@import 'src/styles/style.sass'

.button
    border: 1px solid
    border-radius: 5px
    font-size: 16px
    display: inline-block
    padding: 10px 20px
    +shadow
</style>
