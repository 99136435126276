<template>
  <div>
    <h1>Edit Mode</h1>
    <Loading v-if="loading" />
    <div v-else>
      <router-link class="router-link" to="/app"
        ><Button text="Return Home"
      /></router-link>
      <br /><br />
      <part-form
        v-if="Object.keys(partData).length > 0"
        :initialPartData="partData"
        :showDeleteButton="true"
        primaryButtonText="Save"
        v-model="partData"
        v-on:delete="deletePart()"
        v-on:save="updatePart()"
      />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading.vue";
import PartForm from "@/components/PartForm.vue";
import Button from "@/components/common/Button.vue";

export default {
  data() {
    return {
      partData: {},
      objectID: this.$route.params.objectID,
      loading: false,
    };
  },
  components: { Loading, PartForm, Button },

  async created() {
    var response = await fetch(
      `${process.env.VUE_APP_BACKEND_SERVER_URL}/part-information`,
      {
        method: "GET",
        headers: {
          objectID: this.objectID,
          Authorization: localStorage.getItem("Authorization"),
        },
      }
    );
    console.log(response);
    var data = await response.json();
    if (response.status == 200) {
      this.partData = data.Item;
    }
  },
  methods: {
    async deletePart() {
      if (confirm("are you sure you want to delete?")) {
        this.loading = true;
        var response = await fetch(
          `${process.env.VUE_APP_BACKEND_SERVER_URL}/delete-part`,
          {
            method: "DELETE",
            headers: {
              name: this.partData.name,
              objectID: this.partData.objectID,
              Authorization: localStorage.getItem("Authorization"),
            },
          }
        );
        if (response.status == 200) {
          this.$router.push("/app");
        }
      }
    },
    async updatePart() {
      this.loading = true;
      var response = await fetch(
        `${process.env.VUE_APP_BACKEND_SERVER_URL}/modify-part`,
        {
          method: "PUT",
          headers: {
            "Content-Type":"application/json",
            objectID: this.partData.objectID,
            Authorization: localStorage.getItem("Authorization"),
          },
          body: JSON.stringify({
            "partData": this.partData
          })
        }
      );
      if (response.status == 200) {
        this.$router.push("/app");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'src/styles/style.sass'
</style>
