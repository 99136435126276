<template>
    <div>
        <img src="@/assets/loading.gif" width="250"/>
   </div>
</template>

<script>
export default {}
</script>

