<template>
  <div>
    <h1>Electronics Inventory</h1>
    <router-link class="router-link" to="/create-part">
    <Button text="Create Part"/></router-link>
    <br /><br />
    <SearchBar/>
    <p>Made by Joseph Shepin on 12/26/2021</p>
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import Button from "@/components/common/Button.vue"

export default {
  name: "App",
  components: {
    SearchBar,
    Button
  },
};
</script>

<style lang="sass" scoped>
@import 'src/styles/style.sass'
h1
  margin-top: -40px
  margin-bottom: 10px

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  text-align: center
  color: #2c3e50
  margin-top: 60px
</style>
