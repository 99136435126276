<template>
  <div>
    <h1>Login</h1>
    <input placeholder="Password" type="password" v-model="password" />
    <br />
    <p>{{ message }}</p>
    <br />
    <Button @click="login()" text="Login" />
  </div>
</template>
<script>
import Button from "@/components/common/Button.vue";

export default {
  data() {
    return {
      password: "",
      message: ""
    };
  },
  components: {
    Button,
  },
  methods: {
    async login() {
      this.message = "Logging In"
      var response = await fetch(
        `${process.env.VUE_APP_BACKEND_SERVER_URL}/login?` + new URLSearchParams({
                "password": this.password,
        }),
        {
          method: "GET",
        }
      );
      console.log(response)
      if(response.status == 200){
        this.message = "Access Allowed"
        var key = await response.text();
        console.log(key)
        localStorage.setItem("algolia_api_key", key);
        localStorage.setItem("Authorization", this.password);
        this.$router.push("/app")
      }else{
        this.message = "Access Denied"
      }
    },
  },
};
</script>
