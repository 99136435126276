<template>
  <div>
    <input placeholder="Name (required)" v-model="partData.name" required />
    <br /><br />
    <input placeholder="LCSC Part Number" v-model="partData.LCSCpartnumber" />
    <br /><br />
    <input placeholder="Location (required)" v-model="partData.location" />
    <br /><br />
    <textarea
      placeholder="Description"
      v-model="partData.description"
      lines="2"
    />
    <br /><br />
    <input placeholder="Value" v-model="partData.value" />
    <br /><br />
    <input placeholder="Datasheet URL" v-model="partData.datasheet" />
    <br />
    <br />
    <Button
      v-if="showDeleteButton"
      @click="$emit('delete')"
      class="button"
      text="Delete"
      color="red"
    />
    <Button @click="$emit('save')" v-show="partData.name.length > 0 && partData.location.length > 0" class="button" :text="primaryButtonText" />
  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";

export default {
  components: {
    Button,
  },
  data() {
    return{
        partData: this.initialPartData
    }
  },
  props: {
    showDeleteButton: { type: Boolean, required: false, default: false },
    initialPartData: { type: Object, required: true},
    primaryButtonText: { type: String, required: true }
  },

};
</script>
<style lang="sass">
@import 'src/styles/style.sass'

.button
    &:nth-of-type(odd)
        margin-right: 5px
</style>
